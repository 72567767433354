import React from 'react';
import { StyledText } from './styles';

interface IText {
  children: any;
  type: string;
  color?: string;
  tag?: any;
  noRhythem?: boolean;
  className?: string;
  style?: any;
}

export const Text = ({
  className,
  children,
  type = `title1`,
  color,
  noRhythem = false,
  style,
  tag = `div`,
}: IText) => {
  return (
    <StyledText
      style={style}
      color={color}
      noRhythem={noRhythem}
      as={tag}
      className={`${type} ${className}`}
    >
      {children}
    </StyledText>
  );
};
