import styled from 'styled-components';

interface IStyledText {
  as?: any;
  noRhythem: any;
  color?: any;
  tag?: any;
}

export function setFontSize(fontName, fontSizeRem, lineHeight) {
  return `
    font-family: ${fontName};
    font-size: ${fontSizeRem}rem;
    line-height: ${lineHeight}rem;
  `;
}

export const StyledText = styled.div<IStyledText>`
  color: ${({ theme, color }) => theme.colors[color] || color || `inherit`};
  transition: color 0.5s ease-in-out;
  & a {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.pink_1};
    &:hover {
      color: ${({ theme }) => theme.colors.pink_2};
    }
  }
  & strong {
    background: ${({ theme }) => theme.colors.yellow_2};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 400;
  }
  & em {
    color: ${({ theme }) => theme.colors.white_1_60};
    font-weight: 400;
  }
  & .title1,
  &.title1 {
    font-weight: 700;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 4, 3.75)
        : theme.setFontWithRhythm(`Titillium Web`, 4, 1)}

    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 4.5, 4)
          : theme.setFontWithRhythm(`Titillium Web`, 4.5, 1)
      }
    `}
  }
  & .title2,
  &.title2 {
    font-weight: 700;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 1.75, 1.25)
        : theme.setFontWithRhythm(`Titillium Web`, 1.75, 1.25)}

    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 2, 1.5)
          : theme.setFontWithRhythm(`Titillium Web`, 2, 1.5)
      }
    `}
  }
  & .title3,
  &.title3 {
    font-weight: 400;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 1.625, 2.25)
        : theme.setFontWithRhythm(`Titillium Web`, 1.625, 1)}

    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 2, 2.5)
          : theme.setFontWithRhythm(`Titillium Web`, 2, 1)
      }
    `}
  }
  & .title4,
  &.title4 {
    font-weight: 300;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 1.25, 2)
        : theme.setFontWithRhythm(`Titillium Web`, 1.25, 2)}

    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 1.5, 2.25)
          : theme.setFontWithRhythm(`Titillium Web`, 1.5, 2.25)
      }
    `}
  }
  & .title5,
  &.title5 {
    font-weight: 300
      ${({ theme, noRhythem }) =>
        noRhythem
          ? setFontSize(`Titillium Web`, 1.125, 2)
          : theme.setFontWithRhythm(`Titillium Web`, 1.125, 2)};
    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 1.25, 2.25)
          : theme.setFontWithRhythm(`Titillium Web`, 1.125, 2.25)
      }
    `};
  }
  & .title6,
  &.title6 {
    font-weight: 400;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 1.125, 1.5)
        : theme.setFontWithRhythm(`Titillium Web`, 1.125, 1.5)}

    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 1.25, 1.5)
          : theme.setFontWithRhythm(`Titillium Web`, 1.25, 1.5)
      }
    `}
  }
  & .subtitle1,
  &.subtitle1 {
    font-weight: 400;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 1.25, 1.25)
        : theme.setFontWithRhythm(`Titillium Web`, 1.25, 1.25)}

    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 1.5, 1.5)
          : theme.setFontWithRhythm(`Titillium Web`, 1.5, 1.25)
      }
    `}
  }
  & .subtitle2,
  &.subtitle2 {
    font-weight: 300;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 1.125, 0.75)
        : theme.setFontWithRhythm(`Titillium Web`, 1.125, 0.75)}

    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 1.3125, 1)
          : theme.setFontWithRhythm(`Titillium Web`, 1.3125, 1)
      }
    `}
  }
  & .subtitle3,
  &.subtitle3 {
    font-weight: 300;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 1.125, 1)
        : theme.setFontWithRhythm(`Titillium Web`, 1.125, 1)}

    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 1.3125, 1.3125)
          : theme.setFontWithRhythm(`Titillium Web`, 1.3125, 1.3125)
      }
    `}
  }
  & .body1,
  &.body1 {
    font-weight: 400;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 1, 1.75)
        : theme.setFontWithRhythm(`Titillium Web`, 1, 1.25)}

    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 1.25, 2)
          : theme.setFontWithRhythm(`Titillium Web`, 1.25, 1.25)
      }
    `}
  }
  & .body2,
  &.body2 {
    font-weight: 700;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 0.75, 1.25)
        : theme.setFontWithRhythm(`Titillium Web`, 0.75, 1.25)}

    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 1, 1.5)
          : theme.setFontWithRhythm(`Titillium Web`, 1, 1.5)
      }
    `}
  }
  & .body3,
  &.body3 {
    font-weight: 400;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 1, 1.5)
        : theme.setFontWithRhythm(`Titillium Web`, 1, 1.5)}

    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 1, 1.5)
          : theme.setFontWithRhythm(`Titillium Web`, 1, 1.5)
      }
    `}
  }
  & .body4,
  &.body4 {
    font-weight: 300;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 0.75, 1.25)
        : theme.setFontWithRhythm(`Titillium Web`, 0.75, 1.25)}

    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 0.875, 1.5)
          : theme.setFontWithRhythm(`Titillium Web`, 0.875, 1.5)
      }
    `}
  }
  & .menu1,
  &.menu1 {
    font-weight: 400;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 0.75, 2)
        : theme.setFontWithRhythm(`Titillium Web`, 0.75, 2)}

    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 1, 2.25)
          : theme.setFontWithRhythm(`Titillium Web`, 1, 2.25)
      }
    `}
  }
  & .caption1,
  &.caption1 {
    font-weight: 300;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 0.875, 1.125)
        : theme.setFontWithRhythm(`Titillium Web`, 0.875, 1.125)}
  }
  & .caption2,
  &.caption2 {
    font-weight: 300;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 0.75, 1.125)
        : theme.setFontWithRhythm(`Titillium Web`, 0.75, 1.125)}
  }
  & .paragraph1,
  &.paragraph1 {
    font-weight: 400;
    ${({ theme, noRhythem }) =>
      noRhythem
        ? setFontSize(`Titillium Web`, 0.875, 1)
        : theme.setFontWithRhythm(`Titillium Web`, 0.875, 1)}

    ${({ theme, noRhythem }) => theme.breakpoints.phablet`
      ${
        noRhythem
          ? setFontSize(`Titillium Web`, 1, 1.25)
          : theme.setFontWithRhythm(`Titillium Web`, 1, 1.25)
      }
    `}
  }
`;
